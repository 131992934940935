<template>
    <v-container fluid>
        <v-row>
            <v-col v-for="(category, index) in settings" :key="index" cols="12" sm="6" md="4">
                <v-card class="mx-auto" style="height:100%">
                    <v-card-title>{{ capitalize(category[0].tag) }}</v-card-title>

                    <v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" align="end" justify="center">
                                    <v-btn color="success" @click="saveByTag(category[0].tag)">Save</v-btn>
                                </v-col>
                                <v-col v-for="setting in category" :key="setting.id" cols="12">
                                    <v-text-field
                                        v-model="setting.value"
                                        :label="setting.title"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {};
</script>

<script>
import { mapActions } from 'vuex';

export default {
    data: () => ({
        settings: {}
    }),

    async mounted() {
        try {
            const settings = await this.getSettings();

            settings.map(setting => {
                if (this.settings[setting.tag]) {
                    this.settings[setting.tag] = [
                        ...this.settings[setting.tag],
                        setting
                    ];
                } else {
                    this.settings[setting.tag] = [];
                    this.settings[setting.tag] = [
                        ...this.settings[setting.tag],
                        setting
                    ];
                }
            });

            this.$forceUpdate();
        } catch (err) {
            console.error(err);

            this.$toasted.error('Something went wrong!');
        }
    },

    methods: {
        ...mapActions({
            getSettings: 'settings/getSettings',
            updateSettingsByTags: 'settings/updateSettingsByTags'
        }),

        async saveByTag(tag) {
            const settings = this.settings[tag];

            try {
                await this.updateSettingsByTags({
                    tag,
                    settings
                });

                const successMessage =
                    this.capitalize(tag) + ' settings have been updated!';

                this.$toasted.success(successMessage);
            } catch (err) {
                console.error(err);

                this.$toasted.error('Something went wrong!');
            }
        },

        capitalize(val) {
            if (typeof val !== 'string') {
                return '';
            }

            val = val.replace('_', ' ');

            return val.charAt(0).toUpperCase() + val.slice(1);
        }
    }
};
</script>
